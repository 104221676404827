import { HttpClient, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Action, Company, Department, Language, Location, Role, TemperatureRequirement } from 'src/app/types/config.types';
import { DashboardData } from 'src/app/types/dashboard.types';
import { ImpersonateableCompany } from 'src/app/types/impersonation.types';
import { ActionlistTask, ChecklistItem, GeneralCompanySettings, Interval, RegistrationStorageMeasurePoint } from 'src/app/types/settings.types';
import { User } from 'src/app/types/user.types';
import { environment } from 'src/environments/environment';
import { AppScaffoldService } from '../app-main/app-scaffold/app-scaffold.service';
import { DataMessageResponse, DataResponse, LoginResponse, MessageResponse } from './api-response.types';

@Injectable()
export class ApiService implements OnDestroy {

  private base: string = environment.api.url
  private version: string = environment.api.version


  private _unsubAll: Subject<any>;

  constructor(
    private http: HttpClient,
    private xsrfExtractor: HttpXsrfTokenExtractor
  ) {

    this._unsubAll = new Subject();

    // Check if CSRF token is there and get one if not
    this.initCsrfToken()

  }

  get options() {
    return {
      withCredentials: true,
    }
  }

  initCsrfToken() {
    if (this.xsrfExtractor.getToken() === null) {
      // token is not set, hit get endpoint for token
      this.getCsrfToken().pipe(
        takeUntil(this._unsubAll)
      ).subscribe()
    } else {
    }
  }
  getCsrfToken() {
    return this.http.get(`${this.base}/auth/csrf-cookie`, this.options)
  }


  public dashboard = {

    get: (): Observable<DataResponse<DashboardData>> => {
      return this.http.get<DataResponse<DashboardData>>(`${this.base}/${this.version}/manager/dashboard`, this.options)
    },

  }

  public auth = {

    login: (formData: any): Observable<LoginResponse> => {
      return this.http.post<LoginResponse>(`${this.base}/auth/login`, this.processData(formData), this.options)
    },
  
    logout: (): Observable<{}> => {
      return this.http.get<{}>(`${this.base}/auth/logout`, this.options)
    },

    isAuthenticated: (): Observable<boolean> => {
      return this.http.get<boolean>(`${this.base}/auth/is-authenticated`, this.options)
    },
  
    forgotPassword: (formData: any): Observable<MessageResponse> => {
      return this.http.post<MessageResponse>(`${this.base}/auth/forgot-password`, this.processData(formData), this.options)
    },
  

    verifyResetPassword: (token: string, email: string,): Observable<boolean> => {
      return this.http.get<boolean>(`${this.base}/auth/reset-password/${token}?email=${email}`, this.options)
    },
    resetPassword: (formData: any): Observable<MessageResponse> => {
      return this.http.post<MessageResponse>(`${this.base}/auth/reset-password`, this.processData(formData), this.options)
    },
  
    invitation: (formData: any): Observable<MessageResponse> => {
      return this.http.post<MessageResponse>(`${this.base}/auth/invitation`, this.processData(formData), this.options)
    },
  
    changePassword: (formData: any): Observable<MessageResponse> => {
      return this.http.post<MessageResponse>(`${this.base}/auth/change-password`, this.processData(formData), this.options)
    },

    verifyInvitation: (id: number, signature: string,): Observable<boolean> => {
      return this.http.get<boolean>(`${this.base}/auth/invitation/${id}?signature=${signature}`, this.options)
    },
    acceptInvitation: (id: number, signature: string, formData: any): Observable<boolean> => {
      return this.http.post<boolean>(`${this.base}/auth/invitation/${id}?signature=${signature}`, this.processData(formData), this.options)
    },

    twoFactor: {

      challenge: (formData: any): Observable<MessageResponse> => {
        return this.http.post<MessageResponse>(`${this.base}/auth/two-factor-challenge`, this.processData(formData), this.options)
      },

      enable: {

        init: (): Observable<MessageResponse> => {
          return this.http.post<MessageResponse>(`${this.base}/auth/two-factor-authentication`, {}, this.options)
        },
        
        qr: (): Observable<MessageResponse> => {
          return this.http.get<MessageResponse>(`${this.base}/auth/two-factor-qr-code`, this.options)
        },
      
        confirm: (formData: any): Observable<MessageResponse> => {
          return this.http.post<MessageResponse>(`${this.base}/auth/two-factor-authentication/confirm`, this.processData(formData), this.options)
        },

        cancel: (): Observable<MessageResponse> => {
          return this.http.get<MessageResponse>(`${this.base}/auth/two-factor-authentication/cancel`, this.options)
        }

      },

      delete: (formData: any): Observable<ArrayBuffer> => {
        return this.http.post<ArrayBuffer>(`${this.base}/auth/two-factor-authentication`, this.processDataDel(formData), this.options)
      },   

      codes: (): Observable<DataResponse<string[]>> => {
        return this.http.get<DataResponse<string[]>>(`${this.base}/auth/two-factor-qr-code/recovery-codes`, this.options)
      },
    
      regenerateCodes: (formData: any): Observable<MessageResponse> => {
        return this.http.post<MessageResponse>(`${this.base}/auth/two-factor-qr-code/recovery-codes`, this.processData(formData), this.options)
      },
    
      disable: (): Observable<MessageResponse> => {
        return this.http.post<MessageResponse>(`${this.base}/auth/two-factor-authentication-disable`, this.options)
      }

    },

    profile: (): Observable<DataResponse<User>> => {
      return this.http.get<DataResponse<User>>(`${this.base}/auth/profile`, this.options)
    },
  
    updateProfile: (formData: any): Observable<MessageResponse> => {
      return this.http.post<MessageResponse>(`${this.base}/auth/profile`, this.processData(formData), this.options)
    },



    impersonation: {

      list: (): Observable<DataResponse<ImpersonateableCompany[]>> => {
        return this.http.get<DataResponse<ImpersonateableCompany[]>>(`${this.base}/auth/impersonate`, this.options)
      },

      start: (id: number): Observable<DataResponse<{user: User}>> => {
        return this.http.post<DataResponse<{user: User}>>(`${this.base}/auth/impersonate`, {id: id}, this.options)
      },

      stop: (): Observable<MessageResponse> => {
        return this.http.delete<MessageResponse>(`${this.base}/auth/impersonate`, this.options)
      },

    }

  }

  public config = {

    company: {

      get: (): Observable<DataResponse<Company>> => {
        return this.http.get<DataResponse<Company>>(`${this.base}/${this.version}/settings/company/details`, this.options)
      },

    },

    businessTypes: {

      get: (): Observable<DataResponse<any>> => {
        return this.http.get<DataResponse<any>>(`${this.base}/${this.version}/settings/business-types`, this.options)
      },

    },

    temperatures: {

      get: (): Observable<DataResponse<TemperatureRequirement[]>> => {
        return this.http.get<DataResponse<TemperatureRequirement[]>>(`${this.base}/${this.version}/settings/haccp-temperatures`, this.options)
      },

    },

    intervals: {

      get: (): Observable<DataResponse<Interval[]>> => {
        return this.http.get<DataResponse<Interval[]>>(`${this.base}/${this.version}/settings/intervals`, this.options)
      },

    },

    languages: {

      get: (): Observable<DataResponse<Language[]>> => {
        return this.http.get<DataResponse<Language[]>>(`${this.base}/${this.version}/settings/languages`, this.options)
      },

    },

    roles: {

      get: (): Observable<DataResponse<Role[]>> => {
        return this.http.get<DataResponse<Role[]>>(`${this.base}/${this.version}/settings/roles`, this.options)
      },

    },

    actions: {

      get: (): Observable<DataResponse<Action[]>> => {
        return this.http.get<DataResponse<Action[]>>(`${this.base}/${this.version}/settings/actions`, this.options)
      },

    }

  }


  public haccp = {

    settings: {

      actionlist: {

        get: (): Observable<DataResponse<ActionlistTask[]>> => {
          return this.http.get<DataResponse<ActionlistTask[]>>(`${this.base}/${this.version}/manager/haccp/actionlists`, this.options)
        },

        task: {

          get: (id: number): Observable<DataResponse<ActionlistTask>> => {
            return this.http.get<DataResponse<ActionlistTask>>(`${this.base}/${this.version}/manager/haccp/actionlists/${id}`, this.options)
          },

          create: (formData: any): Observable<DataMessageResponse<ActionlistTask>> => {
            return this.http.post<DataMessageResponse<ActionlistTask>>(`${this.base}/${this.version}/manager/haccp/actionlists/`, this.processData(formData), this.options)
          },
  
          update: (id: number, formData: any): Observable<DataMessageResponse<ActionlistTask>> => {
            return this.http.post<DataMessageResponse<ActionlistTask>>(`${this.base}/${this.version}/manager/haccp/actionlists/${id}`, this.processDataPut(formData), this.options)
          },
  
          delete: (id: number): Observable<DataMessageResponse<ActionlistTask>> => {
            return this.http.delete<DataMessageResponse<ActionlistTask>>(`${this.base}/${this.version}/manager/haccp/actionlists/${id}`, this.options)
          },

        }
      },
      
      cleaninglist: {

        get: (): Observable<DataResponse<ActionlistTask[]>> => {
          return this.http.get<DataResponse<ActionlistTask[]>>(`${this.base}/${this.version}/manager/haccp/cleaninglists`, this.options)
        },

        object: {

          get: (id: number): Observable<DataResponse<ActionlistTask>> => {
            return this.http.get<DataResponse<ActionlistTask>>(`${this.base}/${this.version}/manager/haccp/cleaninglists/${id}`, this.options)
          },

          create: (formData: any): Observable<DataMessageResponse<ActionlistTask>> => {
            return this.http.post<DataMessageResponse<ActionlistTask>>(`${this.base}/${this.version}/manager/haccp/cleaninglists/`, this.processData(formData), this.options)
          },
  
          update: (id: number, formData: any): Observable<DataMessageResponse<ActionlistTask>> => {
            return this.http.post<DataMessageResponse<ActionlistTask>>(`${this.base}/${this.version}/manager/haccp/cleaninglists/${id}`, this.processDataPut(formData), this.options)
          },

          delete: (id: number): Observable<DataMessageResponse<ActionlistTask>> => {
            return this.http.delete<DataMessageResponse<ActionlistTask>>(`${this.base}/${this.version}/manager/haccp/cleaninglists/${id}`, this.options)
          }

        }
      },
      
      registrationStorage: {

        get: (): Observable<DataResponse<RegistrationStorageMeasurePoint[]>> => {
          return this.http.get<DataResponse<RegistrationStorageMeasurePoint[]>>(`${this.base}/${this.version}/manager/haccp/registration-storage`, this.options)
        },

        measurePoint: {

          get: (id: number): Observable<DataResponse<RegistrationStorageMeasurePoint>> => {
            return this.http.get<DataResponse<RegistrationStorageMeasurePoint>>(`${this.base}/${this.version}/manager/haccp/registration-storage/${id}`, this.options)
          },

          create: (formData: any): Observable<DataMessageResponse<RegistrationStorageMeasurePoint>> => {
            return this.http.post<DataMessageResponse<RegistrationStorageMeasurePoint>>(`${this.base}/${this.version}/manager/haccp/registration-storage/`, this.processData(formData), this.options)
          },
  
          update: (id: number, formData: any): Observable<DataMessageResponse<RegistrationStorageMeasurePoint>> => {
            return this.http.post<DataMessageResponse<RegistrationStorageMeasurePoint>>(`${this.base}/${this.version}/manager/haccp/registration-storage/${id}`, this.processDataPut(formData), this.options)
          },
  
          delete: (id: number): Observable<DataMessageResponse<RegistrationStorageMeasurePoint>> => {
            return this.http.delete<DataMessageResponse<RegistrationStorageMeasurePoint>>(`${this.base}/${this.version}/manager/haccp/registration-storage/${id}`, this.options)
          },

        }
      },

      checklistItems: {

        get: (): Observable<DataResponse<ChecklistItem[]>> => {
          return this.http.get<DataResponse<ChecklistItem[]>>(`${this.base}/${this.version}/manager/haccp-settings/checklist-items`, this.options)
        },

        create: (formData: any): Observable<DataMessageResponse<ChecklistItem>> => {
          return this.http.post<DataMessageResponse<ChecklistItem>>(`${this.base}/${this.version}/manager/haccp-settings/checklist-items`, this.processData(formData), this.options)
        },

        update: (id: number, formData: any): Observable<DataMessageResponse<ChecklistItem>> => {
          return this.http.post<DataMessageResponse<ChecklistItem>>(`${this.base}/${this.version}/manager/haccp-settings/checklist-items/${id}`, this.processDataPut(formData), this.options)
        },

        delete: (id: number): Observable<MessageResponse> => {
          return this.http.delete<MessageResponse>(`${this.base}/${this.version}/manager/haccp-settings/checklist-items/${id}`, this.options)
        },
      },

      standardAnswers: {

        get: (): Observable<DataResponse<ChecklistItem[]>> => {
          return this.http.get<DataResponse<ChecklistItem[]>>(`${this.base}/${this.version}/manager/haccp-settings/standard-answers`, this.options)
        },

        create: (formData: any): Observable<DataMessageResponse<ChecklistItem>> => {
          return this.http.post<DataMessageResponse<ChecklistItem>>(`${this.base}/${this.version}/manager/haccp-settings/standard-answers`, this.processData(formData), this.options)
        },

        update: (id: number, formData: any): Observable<DataMessageResponse<ChecklistItem>> => {
          return this.http.post<DataMessageResponse<ChecklistItem>>(`${this.base}/${this.version}/manager/haccp-settings/standard-answers/${id}`, this.processDataPut(formData), this.options)
        },

        delete: (id: number): Observable<MessageResponse> => {
          return this.http.delete<MessageResponse>(`${this.base}/${this.version}/manager/haccp-settings/standard-answers/${id}`, this.options)
        },
      },

    },

    overview: {

      cleaninglist: {

        get: (department_id: number | '', week: number): Observable<DataResponse<any>> => {
          return this.http.get<DataResponse<any>>(`${this.base}/${this.version}/manager/overview/cleaninglist`, {...this.options, params: {department_id: department_id, week: week} } )
        },

        getDetail: (id: number, week: number): Observable<any> => {
          return this.http.get<any>(`${this.base}/${this.version}/manager/overview/cleaninglist/${id}`, {...this.options, params: {week: week} } )
        },

        getResponseDetail: (taskId: number, responseId: number): Observable<any> => {
          return this.http.get<any>(`${this.base}/${this.version}/manager/overview/cleaninglist/${taskId}/${responseId}`, this.options )
        },

      },

      actionlist: {

        get: (department_id: number | '', week: number): Observable<DataResponse<any>> => {
          return this.http.get<DataResponse<any>>(`${this.base}/${this.version}/manager/overview/actionlist`, {...this.options, params: {department_id: department_id, week: week} } )
        },

        getDetail: (id: number, week: number): Observable<any> => {
          return this.http.get<any>(`${this.base}/${this.version}/manager/overview/actionlist/${id}`, {...this.options, params: {week: week} } )
        },

        getResponseDetail: (taskId: number, responseId: number): Observable<any> => {
          return this.http.get<any>(`${this.base}/${this.version}/manager/overview/actionlist/${taskId}/${responseId}`, this.options )
        },

      },

      registrationStorage: {

        get: (department_id: number | '', week: number): Observable<DataResponse<any>> => {
          return this.http.get<DataResponse<any>>(`${this.base}/${this.version}/manager/overview/registration-storage`, {...this.options, params: {department_id: department_id, week: week} } )
        },

        getDetail: (id: number, week: number): Observable<any> => {
          return this.http.get<any>(`${this.base}/${this.version}/manager/overview/registration-storage/${id}`, {...this.options, params: {week: week} } )
        },

        getResponseDetail: (taskId: number, responseId: number): Observable<any> => {
          return this.http.get<any>(`${this.base}/${this.version}/manager/overview/registration-storage/${taskId}/${responseId}`, this.options )
        },

      },

    }

  }

  public company = {

    users: {

      get: (): Observable<DataResponse<User[]>> => {
        return this.http.get<DataResponse<User[]>>(`${this.base}/${this.version}/manager/company/users`, this.options)
      },
      delete: (id: number): Observable<DataResponse<any>> => {
        return this.http.delete<DataResponse<any>>(`${this.base}/${this.version}/manager/company/users/${id}`, this.options)
      },


      user: {

        get: (id: number): Observable<DataResponse<User>> => {
          return this.http.get<DataResponse<User>>(`${this.base}/${this.version}/manager/company/users/${id}`, this.options)
        },
        create: (formData: any): Observable<DataResponse<User>> => {
          return this.http.post<DataResponse<User>>(`${this.base}/${this.version}/manager/company/users`, this.processData(formData), this.options)
        },
        update: (id: number, formData: any): Observable<DataResponse<User>> => {
          return this.http.post<DataResponse<User>>(`${this.base}/${this.version}/manager/company/users/${id}`, this.processDataPut(formData), this.options)
        },
        delete: (id: number): Observable<DataResponse<User>> => {
          return this.http.delete<DataResponse<User>>(`${this.base}/${this.version}/manager/company/users/${id}`, this.options)
        },

      }

    },

    locations: {

      get: (): Observable<DataResponse<Location[]>> => {
        return this.http.get<DataResponse<Location[]>>(`${this.base}/${this.version}/manager/company/locations`, this.options)
      },

      current: {
  
        get: (): Observable<DataResponse<Location>> => {
          return this.http.get<DataResponse<Location>>(`${this.base}/${this.version}/manager/company/locations/current`, this.options)
        },

        set: (client_id: number): Observable<DataResponse<Location>> => {
          return this.http.post<DataResponse<Location>>(`${this.base}/${this.version}/manager/company/locations`, this.processData({client_id: client_id}), this.options)
        }

      },

    },

    other: {

      get: (): Observable<DataResponse<GeneralCompanySettings>> => {
        return this.http.get<DataResponse<GeneralCompanySettings>>(`${this.base}/${this.version}/manager/company/general`, this.options)
      },

      update: (formData: any): Observable<DataResponse<Location>> => {
        return this.http.post<DataResponse<Location>>(`${this.base}/${this.version}/manager/company/general`, this.processData(formData), this.options)
      },


      departments: (): Observable<DataResponse<Department[]>> => {
        return this.http.get<DataResponse<Department[]>>(`${this.base}/${this.version}/manager/company/departments`, this.options)
      },

    }

  }

  public featureUpdates = {
  
    get: (): Observable<DataResponse<any[]>> => {
      return this.http.get<DataResponse<any[]>>(`${this.base}/${this.version}/manager/feature-updates/all`, this.options)
    },

  }


  processData(formData: any): FormData | null {
		let postData = new FormData();

		if (typeof formData !== 'undefined') {
			if (formData !== null) {
				Object.keys(formData).forEach(key => {
					if (Array.isArray(formData[key])) {
            for (let i = 0; i < formData[key].length; i++) {
              if (formData[key][i] !== false && formData[key][i] !== 'undefined') {
                postData.append(key + '[]', formData[key][i]);
              }
            }
					} else if (formData[key] instanceof File) {
            let file: File = formData[key];
            postData.append(key, file, file.name )

          // } else if (formData[key] instanceof String) {
          //   postData.append(key, formData[key]) // Even if string is empty

          } else if (formData[key] instanceof Object) {
						Object.keys(formData[key]).forEach(key2 => {
							if (typeof key2 !== 'undefined') {
								if (formData[key][key2] !== false && formData[key][key2] !== 'undefined') {
									if (key2.indexOf('[') !== -1) {
										postData.append(key + key2, formData[key][key2]);
									} else {
										postData.append(key + '[' + key2 + ']', formData[key][key2]);
									}
								}
							} else {
								postData.append(key, formData[key]);
							}
						});
					} else {
						postData.append(key, formData[key]);
					}
				});

				return postData;
			} else {
				return null;
			}
		} else {
			return null;
		}
  }

  processDataPut(formData: any) {
    return this.processData({...formData, _method: "PUT"})
  }

  processDataDel(formData: any) {
    return this.processData({...formData, _method: "DELETE"})
  }

  ngOnDestroy(): void {
    this._unsubAll.next()
    this._unsubAll.complete();
  }
}




