import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthstateGuard } from './guards/authstate.guard';
import { IsSaasAdminGuard } from './guards/is-saas-admin.guard';
import { LoggedInGuard } from './guards/logged-in.guard';
import { UserResolver } from './modules/user/user.resolver';

const routes: Routes = [
  {
    path      : '',
    pathMatch : 'full',
    redirectTo: 'login',
  },
  {
    path: '',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: '',
    loadChildren: () => import('./modules/app-main/app-main.module').then(m => m.AppMainModule),
    canActivate: [AuthstateGuard],
    resolve: [UserResolver]
  },
  {
    path: 'impersonate',
    loadChildren: () => import('./modules/impersonation/impersonation.module').then(m => m.ImpersonationModule),
    canActivate: [IsSaasAdminGuard],
    resolve: [UserResolver]
  },
  {
    path      : '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
  providers: [UserResolver]
})
export class AppRoutingModule { }
