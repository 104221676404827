import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { ApiService } from '../api/api.service';
import { UserService } from './user.service';

@Injectable()
export class UserResolver implements Resolve<boolean> {

  constructor(
    private api: ApiService,
    private user: UserService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((sub) => {

      this.user.retrieveUser().then(
        (res) => {
          if (res !== null) {
            sub.next(true)
            sub.complete()
          } else {
            sub.next(false)
            sub.complete()
            this.router.navigate(['/login'])
          }
        }
      )

    });
  }
}
