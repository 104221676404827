import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppSplashScreenService } from './services/splash-screen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private splashScreen: AppSplashScreenService
  ) {}
}
