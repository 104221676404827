export const environment = {
  production: true,

  api: {
    url: "//api-staging.dpoint.io",
    version: "v1"
  },

  app_version: "staging-0.2.0"
};
