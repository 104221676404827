import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { takeLast } from 'rxjs/operators';
import { User } from 'src/app/types/user.types';
import { ApiService } from '../api/api.service';
import { LanguageService } from '../config/language/language.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private _lastUserValue: User = null;

  constructor(
    private lang: LanguageService,
    private api: ApiService
  ) {
    // this.retrieveUser()
  }

  private _loggedIn: boolean = false;

  get isLoggedIn() {
    return this._loggedIn
  }

  public setUser(data: User) {
    this._loggedIn = data !== null
    this._lastUserValue = data

    this._user.next(data)
    if (data) {
      console.log("USERDATA setLang")
      this.lang.setLang(data.language.iso, true)
    } else {
      this.lang.unsetAccountLang();
    }
  }

  private _retrieveUser: Promise<User> = null
  public retrieveUser(): Promise<User>  {
    if (this._retrieveUser !== null) {
      return this._retrieveUser
    }

    this._retrieveUser = new Promise<User | null>(
      (resolve, reject) => {
        this.api.auth.profile().subscribe(
          (res) => {
            // User is logged in
            this._retrieveUser = null
            this.setUser(res.data)
            resolve(res.data)
          },
          (err) => {
            // Error, so user is most likely not logged in
            this._retrieveUser = null
            this.setUser(null)
            resolve(null)
          },
          () => {
            this._retrieveUser = null
            reject()
          }
        )
      }
    )

    return this._retrieveUser
  }

  get user() {
    return this._user
  }

  get instantUser() : User {
    return this._lastUserValue
  }
  public getUser() : User {
    return this._lastUserValue
  }
}
