import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconRegistry } from '@angular/material/icon';
import { HttpClient, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { ApiModule } from './modules/api/api.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageService } from './modules/config/language/language.service';
import { FormPageService } from './components/form-page/form-page.service';
import { ConfigModule } from './modules/config/config.module';
import { ApiService } from './modules/api/api.service';
import { UserService } from './modules/user/user.service';
// import { UpdateDialogModule } from './components/update-dialog/update-dialog.module';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
      
    }),

    // UpdateDialogModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    ApiModule,
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    FormPageService,
    ConfigModule
  ]
})
export class AppModule {
  constructor(
    iconRegistry: MatIconRegistry,
    ) {
    iconRegistry.setDefaultFontSetClass('material-icons-outlined');
  }  
}
