import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageService } from './language/language.service';
import { GeneralConfigService } from './general/general-config.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    LanguageService,
    GeneralConfigService
  ]
})
export class ConfigModule { }
