import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../modules/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class IsSaasAdminGuard implements CanActivate, CanActivateChild {

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isLoggedInActivation(state);
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isLoggedInActivation(state);
  }


  isLoggedInActivation(route: RouterStateSnapshot): Promise<boolean | UrlTree> | boolean {

    if (route.url.indexOf('logout') >= 0) {
      return true;
    }

    return new Promise(
      (resolve, reject) => {
        
        this.userService.retrieveUser().then(
          (res) => {
            if (res?.role?.name === 'saas-admin' || res?.impersonated_by?.role.name === 'saas-admin') {
              resolve(true)
            } else {
              resolve(this.router.createUrlTree(['overview']))
            }
          },
          (err) => {
            // Error, so user is most likely not logged in, can activate
            // console.log(err)
            resolve(false)
          }
        )
      }
    )
  }
  
}
