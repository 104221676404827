import { EventEmitter, Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { filter, shareReplay, take, takeUntil } from 'rxjs/operators';
import { Company } from 'src/app/types/config.types';
import { ApiService } from '../../api/api.service';
import { AppMainModule } from '../../app-main/app-main.module';
import { UserService } from '../../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class GeneralConfigService implements OnDestroy {

  private _unsubAll: Subject<any>;
  constructor(
    private api: ApiService,
    private userService: UserService
  ) {

    this._unsubAll = new Subject();

    this.userService.user.pipe(
      takeUntil(this._unsubAll)
    ).subscribe(
      (user) => {
        if (user) {
          this.userLoggedIn()
        } else {
          this.userLoggedOut()
        }
      }
    )
  }
  ngOnDestroy(): void {
    this._unsubAll.next()
    this._unsubAll.complete()
  }


  userLoggedIn() {
    if (this.userService.instantUser.role.name === "saas-admin") return;
    this.updateCompanyDetails()
  }
  userLoggedOut() {
    //TODO: In future, these settings might stay on the users' device for theming purposes
    this._companyDetails = undefined
    this.companyDetails.next(undefined)
  }

  private _companyDetails: Company = undefined
  public companyDetails: Subject<Company> = new ReplaySubject<Company>(1);


  updateCompanyDetails() : Promise<Company> {
    return new Promise(
      (resolve, reject) => {
        if (this._companyDetails !== undefined) {
          resolve(this._companyDetails)
          return
        }

        this.api.config.company.get().subscribe(
          (res) => {
            this._companyDetails = res.data
            this.companyDetails.next(res.data)
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
      }
    )
  }

  isSingleBusiness() : Promise<boolean> {
    return new Promise<boolean>(
      (resolve, reject) => {

        this.companyDetails.pipe(
          filter((details) => details !== undefined),
          take(1)
        ).subscribe(
          (res) => {
            if (!res) {
              resolve(undefined)
              return undefined;
            }
            if (res.business_type.id === 3) {
              // 3 is Single business
              resolve(true)
              return true
            } else {
              resolve(false)
              return false
            }
          },
          (err) => {
            reject(err)
            return false
          }
        )

      }
    )
  }
}
