import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Language } from 'src/app/types/config.types';
import { ApiService } from '../../api/api.service';

import "./locale/en.js";
import "./locale/nl.js";

export let LANG_KEY: string = "conf_language";


@Injectable({
  providedIn: 'root',
})
export class LanguageService {

  constructor(
    private translate: TranslateService,
    // private user: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService
  ) {

    console.log("INIT LANGUAGESERVICE")
    this.api.config.languages.get().subscribe(
      (res) => {
        this.languages = res.data
        // this.setLang(this.getStoredLang())
        this.startListeningUrlParameters();
      },
      (err) => {
        // Set stored language, or default to english
        this.setLang(this.getStoredLang())
        // this.startListeningUrlParameters();
      }
    )
  }


  private startListeningUrlParameters() {

    this.route.queryParamMap.subscribe((params) => {

      // console.log(params)
      console.log(`params.has('ln'): ${params.has('ln')}`);

      // this.user.user.pipe(first()).toPromise().then((user) => {

        // console.log(user)
        // if (this.user.isLoggedIn) {
        //   return;
        // } else {
          if (params.has("ln") == false) {
            console.log(`currentLang: ${this.currentLang}`)
            if (this.currentLang == undefined) {
              this.setLang(this.getStoredLang());
            }
            return;
          };
      
          var toLang = params.get("ln")
    
          if (this.languages.findIndex((lang) => lang.iso === toLang) >= 0) {
            if (this.currentLang != toLang){
              console.log("URL setLang")
              this.setLang(toLang);
            }
          }
        // }

        var newParams = {...this.route.snapshot.queryParams};
        delete newParams.ln;

        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: newParams,
          queryParamsHandling: ''
        });

      // })

    })

  }

  private accountLang: string = undefined
  private currentLang: string = undefined
  public languages: Language[] = []

  setLang(iso: string, fromAccount: boolean = false) {

    if (iso === this.currentLang) {

      if (fromAccount) {
        this.accountLang = iso;
      }

      console.info("Lang already set")
      return
    }

    console.log(`accountLang: ${this.accountLang}... ${fromAccount}`)

    if (fromAccount == true) {
      this.accountLang = iso;
    } else {
      if (this.accountLang != undefined) return;
    }

    console.info("Setlang: ", iso)

    this.currentLang = iso
    setTimeout(() => {
      this.translate.use(iso)
      localStorage.setItem(LANG_KEY, this.currentLang.toString())
    });

    moment.locale(iso)
  }

  unsetAccountLang() {
    this.accountLang = undefined;
    this.setLang(this.currentLang);
  }

  getCurrentLang(): string {
    return this.currentLang
  }

  getStoredLang(): string {
    let storedIso = localStorage.getItem(LANG_KEY)

    if (!storedIso?.match("^[a-z]{2}$")) {
      this.setLang('nl')
      return 'nl'
    }

    if (storedIso) {
      return storedIso
    } else {
      return 'nl' // Always defaults to Dutch if not stored yet
    }
  }
 }
